/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/7/2020
 * @Example
 */

import Api                        from '../../services/api';
import {delay}                    from "redux-saga";
import {cancel, take, takeLatest} from "redux-saga/effects";
import {types}                    from "../../redux/settings/settings_actions";

export default function SettingSagas() {
    return [
        watchSettingUpdate(),
        watchSettingList(),
        watchSettingListAll(),
    ];
}

export function* settingUpdate(data) {
    try {
        const settingUpdate = yield Api.put('/setting/edit',data.params);
        if (settingUpdate && settingUpdate.data) {
            data.cb && data.cb(null,settingUpdate.data)
        }
        else{
            data.cb && data.cb(settingUpdate,null)
        }
    }
    catch (e) {
        console.log('settingUpdate error');
    }

}

export function* watchSettingUpdate() {
    while (true){
        const watcher = yield takeLatest(types.SETTING_UPDATE, settingUpdate);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* settingList(data) {
    try {
        yield delay(300);
        const settingLists = yield Api.put('/setting/list',data.params);
        if (settingLists && settingLists.data) {
            data.cb && data.cb(null,settingLists.data);
            return settingLists;
        }
        else{
            data.cb && data.cb(settingLists,null)
            return null;
        }
    }
    catch (e) {
        console.log('settingList error');
        return null;
    }
}

export function* watchSettingList() {
    while (true){
        const watcher = yield takeLatest(types.SETTING_LIST,settingList);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* settingListAll(data) {
    try {
        yield delay(300);
        const settingLists = yield Api.put('/setting/get-all',data.params);
        if (settingLists && settingLists.data) {
            data.cb && data.cb(null,settingLists.data);
            return settingLists;
        }
        else{
            data.cb && data.cb(settingLists,null);
            return null;
        }
    }
    catch (e) {
        console.log('settingListAll error');
        return null;
    }
}

export function* watchSettingListAll() {
    while (true){
        const watcher = yield takeLatest(types.SETTING_LIST_ALL, settingListAll);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
