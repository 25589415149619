/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/08/09 10:37
 * @file src/routes/routes.js
 */

import React, { Component } from 'react';
import { Router, Route, browserHistory,IndexRoute } from 'react-router'
import App from '../container/app';
import Config from '../config';

import ModuleNotFound from '../components/not-found/not-found';
import ErrorPage from '../components/error-500/error-500';
import NotPermission from '../components/not-permission/not-permission';

import Dashboard from '../components/dashboard/dashboard';
import AuthPage from '../components/login/auth';
import MailLink from '../components/maillink/maillink';

import W00F0010 from "../components/W0X/W00/W00F0010/W00F0010";

import W09F2000 from '../components/W0X/W09/W09F2000/W09F2000';

import W77F1000 from "../components/W7X/W77/W77F1000/W77F1000"
import W75F2000 from "../components/W7X/W75/W75F2000/W75F2000";
import W75F2002 from "../components/W7X/W75/W75F2002/W75F2002";
import W75F2010 from "../components/W7X/W75/W75F2010/W75F2010";
import W75F2005 from "../components/W7X/W75/W75F2005/W75F2005";
import W77F1012 from "../components/W7X/W77/W77F1012/W77F1012";
import W77F1014 from "../components/W7X/W77/W77F1014/W77F1014";
import W75F8000 from "../components/W7X/W75/W75F8000/W75F8000";

import Report from "../components/report/report";
import W09F0001Popup from "../components/W0X/W09/W09F0001/W09F0001Popup";
import W09F2023 from "../components/W0X/W09/W09F2023/W09F2023";
import W09F2005 from "../components/W0X/W09/W09F2005/W09F2005";
import W09F2010 from '../components/W0X/W09/W09F2010/W09F2010';
import W09F2060 from '../components/W0X/W09/W09F2060/W09F2060';
import W09F2061 from "../components/W0X/W09/W09F2061/W09F2061";
import W09F2070 from "../components/W0X/W09/W09F2070/W09F2070";
import W09F2071 from "../components/W0X/W09/W09F2071/W09F2071";
import W09F2080 from "../components/W0X/W09/W09F2080/W09F2080";
import W09F2081 from "../components/W0X/W09/W09F2081/W09F2081";
import W21F2010 from "../components/W2X/W21/W21F2010/W21F2010";
import W25F2033 from "../components/W2X/W25/W25F2033/W25F2033";
import W29F2006 from "../components/W2X/W29/W29F2006/W29F2006";
import W29F2011 from "../components/W2X/W29/W29F2011/W29F2011";
import W29F2005 from "../components/W2X/W29/W29F2005/W29F2005";
import W29F2010 from "../components/W2X/W29/W29F2010/W29F2010";
import W38F2000 from '../components/W3X/W38/W38F2000/W38F2000';
import W38F2001 from '../components/W3X/W38/W38F2001/W38F2001';
import W38F2002 from '../components/W3X/W38/W38F2002/W38F2002';
import W84F3000 from "../components/W8X/W84/W84F3000/W84F3000";
import W13F1000 from "../components/W1X/W13/W13F1000/W13F1000";
import W09F1000 from "../components/W0X/W09/W09F1000/W09F1000";
import W09F2152 from "../components/W0X/W09/W09F2152/W09F2152";

import W09F1005 from "../components/W0X/W09/W09F1005/W09F1005";
import W29F3000 from "../components/W2X/W29/W29F3000/W29F3000";
import W09F1501 from "../components/W0X/W09/W09F1501/W09F1501";
import W09F1500 from "../components/W0X/W09/W09F1500/W09F1500";
import W09F9000 from "../components/W0X/W09/W09F9000/W09F9000";
import W09F2020 from "../components/W0X/W09/W09F2020/W09F2020";
import W09F2002 from "../components/W0X/W09/W09F2002/W09F2002";
import W09F2220 from "../components/W0X/W09/W09F2220/W09F2220";
import W09F2270 from "../components/W0X/W09/W09F2270/W09F2270";
import W21F2005 from "../components/W2X/W21/W21F2005/W21F2005";
import W25F2015 from '../components/W2X/W25/W25F2015/W25F2015';
import W25F2041 from '../components/W2X/W25/W25F2041/W25F2041';
import W39F1011 from "../components/W3X/W39/W39F1011/W39F1011";
import W51F1001 from '../components/W5X/W51F1001/W51F1001';
import W51F1000 from "../components/W5X/W51F1000/W51F1000";


// import Test from "../components/Test/Test";
// import Demo from "../components/Demo/Demo";
import W39F1020 from "../components/W3X/W39/W39F1020/W39F1020";
import W39F1025 from "../components/W3X/W39/W39F1025/W39F1025";
import W25F2001 from "../components/W2X/W25/W25F2001/W25F2001";
import W25F2023 from "../components/W2X/W25/W25F2023/W25F2023"
import W52F1000 from "../components/W5X/W52F1000/W52F1000";
import W29F2030 from "../components/W2X/W29/W29F2030/W29F2030";
import W29F2040 from "../components/W2X/W29/W29F2040/W29F2040";
import W29F2065 from "../components/W2X/W29/W29F2065/W29F2065";
import W29F2060 from "../components/W2X/W29/W29F2060/W29F2060"
import W29F2050 from "../components/W2X/W29/W29F2050/W29F2050";
import W29F2070 from "../components/W2X/W29/W29F2070/W29F2070";

import W52F1010 from "../components/W5X/W52F1010/W52F1010";
// import Test from "../components/Test/Test";
import W25F2000 from "../components/W2X/W25/W25F2000/W25F2000";

import W25F4000 from "../components/W2X/W25/W25F4000/W25F4000";
import W25F2002 from "../components/W2X/W25/W25F2002/W25F2002";
import W25F2010 from "../components/W2X/W25/W25F2010/W25F2010";
import W39F2000 from "../components/W3X/W39/W39F2000/W39F2000";

import W29F2100 from "../components/W2X/W29/W29F2100/W29F2100";
import W29F2000 from "../components/W2X/W29/W29F2000/W29F2000";

import W29F2025 from "../components/W2X/W29/W29F2025/W29F2025";
import W29F2015 from "../components/W2X/W29/W29F2015/W29F2015";

import W39F1000 from "../components/W3X/W39/W39F1000/W39F1000";
import W39F1005 from "../components/W3X/W39/W39F1005/W39F1005";
import W39F2001 from "../components/W3X/W39/W39F2001/W39F2001";
import W39F3025 from "../components/W3X/W39/W39F3025/W39F3025";
import W39F3030 from "../components/W3X/W39/W39F3030/W39F3030";

import W39F3010 from "../components/W3X/W39/W39F3010/W39F3010";

import W39F1030 from "../components/W3X/W39/W39F1030/W39F1030";
import W39F1010 from "../components/W3X/W39/W39F1010/W39F1010";
import W39F2030 from "../components/W3X/W39/W39F2030/W39F2030";
import W09F2150 from "../components/W0X/W09/W09F2150/W09F2150";
import W77F1010 from "../components/W7X/W77/W77F1010/W77F1010";
import W77F1011 from "../components/W7X/W77/W77F1011/W77F1011";
import W77F1013 from "../components/W7X/W77/W77F1013/W77F1013";
import W09F2031 from "../components/W0X/W09/W09F2031/W09F2031";
import W09F2030 from "../components/W0X/W09/W09F2030/W09F2030";
import W25F2011 from "../components/W2X/W25/W25F2011/W25F2011";
import W29F2035 from "../components/W2X/W29/W29F2035/W29F2035";
import W09F4000 from "../components/W0X/W09/W09F4000/W09F4000";
import W09F2012 from "../components/W0X/W09/W09F2012/W09F2012";
import W00F0100 from '../components/W0X/W00/W00F0100/W00F0100';
import W09F3000 from "../components/W0X/W09/W09F3000/W09F3000";
import W89F1000 from "../components/W8X/W89/W89F1000/W89F1000";
import W09F3010 from "../components/W0X/W09/W09F3010/W09F3010";
import W51F1100 from "../components/W5X/W51F1100/W51F1100";
import W51F1101 from "../components/W5X/W51F1101/W51F1101";
import W39F3020 from "../components/W3X/W39/W39F3020/W39F3020";

import W39F3000 from "../components/W3X/W39/W39F3000/W39F3000";
import W39F3002 from "../components/W3X/W39/W39F3002/W39F3002";
import Communication from "../components/communication";

import W09F2225 from "../components/W0X/W09/W09F2225/W09F2225";
import W09F2280 from "../components/W0X/W09/W09F2280/W09F2280";
import W09F2282 from "../components/W0X/W09/W09F2282/W09F2282";
import W09F2272 from '../components/W0X/W09/W09F2272/W09F2272';

import W39F3035 from "../components/W3X/W39/W39F3035/W39F3035";
import W39F3036 from "../components/W3X/W39/W39F3036/W39F3036";

class Routes extends Component {

    onRouteUpdate = (e) => {
        window.scrollTo(0, 0);
        if (Config.filters) {
            Config.filters.setFilter(null); //Clear general filters on header
        }
    };

    render(){
        return (
            <Router onUpdate={this.onRouteUpdate} history={browserHistory}>
                <Route path={Config.getRootPath()} component={App}>
                    <IndexRoute component={Dashboard}/>

                    <Route path="admin"
                           component={()=><section>Redirecting...</section>}/>

                    <Route path="dashboard" component={Dashboard} />
                    <Route path="auth" component={AuthPage} />

                    {/* W00 */}
                    <Route path="W00F0010" component={W00F0010} />
                    <Route path="W00F0100" component={W00F0100} />

                    {/* W09*/}
                    <Route path="W09F0001" component={W09F0001Popup} />
                    <Route path="W09F2023" component={W09F2023} />
                    <Route path="W09F1000" component={W09F1000} />
                    <Route path="W09F1005" component={W09F1005} />
                    <Route path="W09F2000" component={W09F2000} />
                    <Route path="W09F2005" component={W09F2005} />
                    <Route path="W09F2010" component={W09F2010} />
                    <Route path="W09F2012" component={W09F2012} />
                    <Route path="W09F2060" component={W09F2060} />
                    <Route path="W09F2061" component={W09F2061} />
                    <Route path="W09F2070" component={W09F2070} />
                    <Route path="W09F2071" component={W09F2071}/>
                    <Route path="W09F1501" component={W09F1501} />
                    <Route path="W09F2080" component={W09F2080}/>
                    <Route path="W09F1500" component={W09F1500}/>
                    <Route path="W09F2081" component={W09F2081}/>
                    <Route path="W09F1500" component={W09F1500}/>
                    <Route path="W09F9000" component={W09F9000}/>
                    <Route path="W09F2020" component={W09F2020}/>
                    <Route path="W09F2150" component={W09F2150}/>
                    <Route path="W09F2152" component={W09F2152}/>
                    <Route path="W09F2030" component={W09F2030}/>
                    <Route path="W09F2031" component={W09F2031}/>
                    <Route path="W09F2030" component={W09F2030}/>
                    <Route path="W09F3010" component={W09F3010}/>
                    <Route path="W09F3000" component={W09F3000}/>
                    <Route path="W09F4000" component={W09F4000}/>
                    <Route path="W09F2002" components={W09F2002}/>
                    <Route path="W09F2220" components={W09F2220} />
                    <Route path="W09F2225" component={W09F2225}/>
                    <Route path="W09F2280" component={W09F2280}/>
                    <Route path="W09F2282" component={W09F2282}/>
                    <Route path="W09F2272" component={W09F2272}/>

                     {/* W13 */}
                    <Route path="W13F1000" component={W13F1000} />

                    {/* W25 */}
                    <Route path="W25F2000" component={W25F2000}/>
                    <Route path="W25F2001" component={W25F2001} />
                    <Route path="W25F2002" component={W25F2002}/>
                    <Route path="W25F2010" component={W25F2010}/>
                    <Route path="W25F2033" component={W25F2033}/>
                    <Route path="W25F2023" components={W25F2023}  />
                    <Route path="W25F2011" component={W25F2011}/>
                    <Route path="W25F4000" components={W25F4000} />

                    {/* W29 */}
                    <Route path="W29F2000" component={W29F2000} />
                    <Route path="W21F2010" component={W21F2010} />
                    <Route path="W29F2006" component={W29F2006} />
                    <Route path="W29F2005" component={W29F2005} />
                    <Route path="W29F3000" component={W29F3000} />
                    <Route path="W29F2010" component={W29F2010} />
                    <Route path="W29F2011" component={W29F2011} />
                    <Route path="W29F2015" component={W29F2015} />
                    <Route path="W29F2025" component={W29F2025} />
                    <Route path="W29F2035" component={W29F2035} />
                    <Route path="W29F2065" component={W29F2065}/>
                    <Route path="W29F2060" component={W29F2060} />

                    {/* W29 */}
                    <Route path="W21F2005" component={W21F2005} />
                    <Route path="W29F2100" component={W29F2100} />
                    <Route path="W29F2030" component={W29F2030} />
                    <Route path="W29F2040" component={W29F2040} />
                    <Route path="W29F2050" component={W29F2050} />
                    <Route path="W29F2070" component={W29F2070} />

                    {/* W25 */}
                    <Route path="W25F2015" component={W25F2015} />
                    <Route path="W25F2041" component={W25F2041} />

                    {/* W38 */}
                    <Route path="W38F2000" component={W38F2000} />
                    <Route path="W38F2001" component={W38F2001} />
                    <Route path="W38F2002" component={W38F2002} />

                    {/* W3X*/}
                    <Route path="W39F1010" component={W39F1010} />
                    {/* W39 */}
                    <Route path="W39F2000" component={W39F2000} />

                    {/* W39 */}
                    <Route path="W39F1000" component={W39F1000} />
                    <Route path="W39F1005" component={W39F1005} />
                    <Route path="W39F1011" component={W39F1011} />
                    <Route path="W39F3020" component={W39F3020} />

                    {/* W39 */}
                    <Route path="W39F2001" component={W39F2001} />
                    <Route path="W39F2030" component={W39F2030} />
                    <Route path="W39F3000" component={W39F3000} />
                    <Route path="W39F3002" component={W39F3002} />

                    {/* W39 */}
                    <Route path="W39F1020" component={W39F1020} />
                    <Route path="W39F1025" component={W39F1025} />
                    <Route path="W39F3025" component={W39F3025} />
                    <Route path="W39F3030" component={W39F3030} />

                    {/* W39 */}
                    <Route path="W39F3010" component={W39F3010} />
                    <Route path="W39F3035" component={W39F3035} />
                    <Route path="W39F3036" component={W39F3036} />


                    {/* W38 */}
                    <Route path="W38F2000" component={W38F2000} />
                    <Route path="W38F2001" component={W38F2001} />

                    {/* W51 */}
                    <Route path="W51F1000" component={W51F1000} />
                    <Route path="W51F1100" components={W51F1100} />
                    <Route path="W51F1101" components={W51F1101} />

                    <Route path="W77F1000" component={W77F1000} />
                    {/* W52 */}
                    <Route path="W52F1000" component={W52F1000} />

                    <Route path="W52F1010" components={W52F1010} />
                    {/* W75 */}
                    <Route path="W75F2000" component={W75F2000}/>
                    <Route path="W75F2002" component={W75F2002}/>
                    <Route path="W75F2010" component={W75F2010}/>

                    {/* W77 */}
                    <Route path="W77F1010" component={W77F1010}/>
                    <Route path="W77F1011" component={W77F1011}/>
                    <Route path="W77F1013" component={W77F1013}/>

                    {/*<Route path="W75F4090" component={W75F4090}/>*/}
                    <Route path="W75F2005" component={W75F2005}/>
                    <Route path="W75F2010" component={W75F2010}/>
                    <Route path="W77F1012" component={W77F1012}/>
                    <Route path="W77F1014" component={W77F1014}/>
                    <Route path="W75F8000" component={W75F8000}/>

                    {/* W84 */}
                    <Route path="W84F3000" component={W84F3000}/>
                    <Route path="W89F1000" component={W89F1000}/>

                    <Route path="W51F1001" component={W51F1001}/>

                    <Route path="W39F1030" component={W39F1030}/>

                    <Route path="W09F2270" component={W09F2270}/>

                    {/*<Route path="Test" component={Test}/>*/}
                    {/* <Route path="ui-demo" component={Demo}/> */}

                    <Route path="maillink" component={MailLink}/>

                    <Route path="report" component={Report}/>

                    <Route path="error-page" component={ErrorPage}/>

                    <Route path="not-permission" component={NotPermission}/>

                    <Route path="communication" component={Communication}/>

                    <Route path='*' exact={true} component={ModuleNotFound} />
                </Route>
            </Router>
        );
    };
}

export default Routes;
