/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/31/2019
 * @Example
 */

import Api                                        from '../../services/api';
import {put, cancel, take, takeLatest, takeEvery} from "redux-saga/effects";
import { types }                                  from "../../redux/general/general_actions";
import { delay }                                  from "redux-saga";
import Config                                     from "../../config";

export default function GeneralSagas() {
    return [
        watchGetOrgCharts(),
        watchGetCboProjects(),
        watchGetCboDuty(),
        watchGetCboDuty2(),
        watchGetCboEmployees(),
        watchGetAttachmentsByTransID(),
        watchExportExcelTemplate(),
        watchGetCboTeam(),
        watchGetCboWorks(),
        watchGetCboDepartments(),
        watchGetCboAppStatus(),
        watchgetGridGeneralInformation(),
        watchGetRequiredFields(),
        watchGetCboGenders(),
        watchGetCboStatusWork(),
        watchGetCboWarehouse(),
        watchgetGridGeneralInformation(),
        watchGetLoadCurrency(),
        watchGetLoadEducation(),
        watchGetLoadProfessionalLevel(),
        watchGetDateByTrans(),
        watchGetCboPeriod(),
        watchGetLoadListAttachments(),
        watchGetLoadDepartments(),
        watchGetCandidateProfile(),
        watchLoadSetting(),
        watchExportMulti()
    ];
}

//get organiaztional..
export function* getOrgCharts(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w00f0010/get-structure', {...data.params, Language: Config.language || 84});
        if (response && response.data) {
            yield put({ type: types.GET_CBO_ORGCHART_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get organizational error');
    }
}

export function* watchGetOrgCharts() {
    while (true) {
        const watcher = yield takeLatest(types.GET_CBO_ORGCHART, getOrgCharts);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get duty..
export function* getCboDuty(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w09f2161/load-drd-produty', data.params);
        if (response && response.data) {
            yield put({ type: types.GET_CBO_DUTY_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo duty error');
    }
}

export function* watchGetCboDuty() {
    while (true) {
        const watcher = yield takeLatest(types.GET_CBO_DUTY, getCboDuty);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get duty 2..
export function* getCboDuty2(data) {
    try {
        yield delay(300);
        let {params={}} =data||{}
        params.Language= params.Language||Config.language || '84'
        const response = yield Api.put('/standard/get-positions',params);
        if (response && response.data) {
            yield put({ type: types.GET_CBO_DUTY2_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo duty 2 error', e);
    }
}

export function* watchGetCboDuty2() {
    while (true) {
        const watcher = yield takeLatest(types.GET_CBO_DUTY2, getCboDuty2);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo employee..
export function* getCboEmployees(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/standard/load-cbo-employee', data.params);
        if (response && response.data) {
            yield put({ type: types.GET_CBO_EMPLOYEE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo employees error');
    }
}

export function* watchGetCboEmployees() {
    while (true) {
        const watcher = yield takeEvery(types.GET_CBO_EMPLOYEE, getCboEmployees);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo project..
export function* getCboProjects(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w09f2161/load-cbo-project', data.params);
        if (response && response.data) {
            yield put({ type: types.GET_CBO_PROJECT_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo projects error');
    }
}

export function* watchGetCboProjects() {
    while (true) {
        const watcher = yield takeLatest(types.GET_CBO_PROJECT, getCboProjects);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo departments..
export function* getCboDepartments(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/standard/load-cbo-department', data.params);
        if (response && response.data) {
            yield put({ type: types.GENERAL_GET_CBO_DEPARTMENTS_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo departments error');
    }
}

export function* watchGetCboDepartments() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_DEPARTMENTS, getCboDepartments);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo team..
export function* getCboTeam(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/standard/load-cbo-team', data.params);
        if (response && response.data) {
            yield put({ type: types.GENERAL_GET_CBO_TEAM_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo team error');
    }
}

export function* watchGetCboTeam() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_TEAM, getCboTeam);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo works..
export function* getCboWorks(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/standard/load-combo-work', data.params);
        if (response && response.data) {
            yield put({ type: types.GENERAL_GET_CBO_WORKS_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo works error');
    }
}

export function* watchGetCboWorks() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_WORKS, getCboWorks);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCboAppStatus(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/standard/load-cbo-app-status', data.params);
        if (response && response.data) {
            yield put({ type: types.GET_CBO_APP_STATUS_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo appstatus error');
    }
}

export function* watchGetCboAppStatus() {
    while (true) {
        const watcher = yield takeLatest(types.GET_CBO_APP_STATUS, getCboAppStatus);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//Get genders..
export function* getCboGenders(data) {
    try {
        yield delay(300);
        const response = yield {
            data: [
                {
                    SexID: 0,
                    SexName: Config.lang("DHR_Nam")
                },
                {
                    SexID: 1,
                    SexName: Config.lang("DHR_Nu")
                },
                {
                    SexID: 3,
                    SexName: Config.lang("DHR_Khac")
                },
            ]
        };
        if (response && response.data) {
            yield put({ type: types.GET_CBO_GENDER_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo genders error');
    }
}

export function* watchGetCboGenders() {
    while (true) {
        const watcher = yield takeLatest(types.GET_CBO_GENDER, getCboGenders);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//Get status work..
export function* getCboStatusWork(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/standard/get-status-work');
        if (response && response.data) {
            yield put({ type: types.GET_CBO_STATUS_WORK_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo status work error');
    }
}

export function* watchGetCboStatusWork() {
    while (true) {
        const watcher = yield takeLatest(types.GET_CBO_STATUS_WORK, getCboStatusWork);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get attachments by TransID..
export function* getAttachmentsByTransID(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/attachment/get-attachment-by-key', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get attachments by transID error');
    }
}

export function* watchGetAttachmentsByTransID() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_ATTACHMENTS_BY_TRANSID, getAttachmentsByTransID);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCboWarehouse(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/standard/load-cbo-warehouse', data.params);
        if (response && response.data) {
            yield put({ type: types.GET_CBO_WAREHOUSE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo warehouse');
    }
}

export function* watchGetCboWarehouse() {
    while (true) {
        const watcher = yield takeLatest(types.GET_CBO_WAREHOUSE, getCboWarehouse);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}


/**
 * Export excel template...
 * @param data: {params, cb}
 * @param params: {
 *     token,
 *     ReportTypeID
 *     ReportID,
 *     TemplateURL,
 *     isPDF,
 *     Params,
 * }
 * @returns {IterableIterator<Promise<true>|*>}
 */

function checkUrlFileName(filename) {
    //if file name had special character return valid data
    const specialChar = filename.search("UTF-8");
    if (specialChar !== -1) {
        const reFileName = filename.slice(specialChar + 7, filename.length);
        return filename = decodeURIComponent(reFileName);
    }
    return filename; // else return original data
}

export function* exportExcelTemplate(data) {
    try {
        yield delay(300);
        let response = yield Api.putExport('/export/excel-report-general', data.params);
        if (response && response.ok) {
            let _res = null;
            if (response.type === "json") {
                _res = yield response.data.json();
                if (_res && _res.data) {
                    data.cb && data.cb(null, _res.data);
                } else {
                    data.cb && data.cb(_res, null)
                }
            } else {
                const content = yield response.data.blob();
                const datetime = new Date().getTime();
                const _fileName = response.filename ? checkUrlFileName(response.filename) : "data-export-hr-" + datetime + '.xls';
                _res = {
                    fileName: _fileName,
                    fileExt: Config.helpers.getExtFile(_fileName),
                    URL: URL.createObjectURL(content),
                };
                if (_res && _res.URL) {
                    data.cb && data.cb(null, _res);
                } else {
                    data.cb && data.cb(_res, null);
                }
            }
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('export excel template error');
    }
}

export function* watchExportExcelTemplate() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_EXPORT_EXCEL_TEMPLATE, exportExcelTemplate);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getGridGeneralInformation(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w75f2000/load-general-information', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data[0])
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get grid error');
    }

}

export function* watchgetGridGeneralInformation() {
    while (true) {
        const watcher = yield takeLatest(types.GET_GRID_GENERAL_INFORMATION, getGridGeneralInformation);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getRequiredFields(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/standard/check-required-field", data.params);
        yield put({ type: types.GENERAL_GET_CHECK_REQUIRED_FIELD_SUCCESS, data: response.data });
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("check required fields error");
    }
}

export function* watchGetRequiredFields() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CHECK_REQUIRED_FIELD, getRequiredFields);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

// export function* getCboWareHouse(data) {
//     try {
//         yield delay(300);
//         const response = yield Api.put('/standard/load-cbo-warehouse', data.params);
//         if (response && response.data) {
//             yield put({ type: types.GET_CBO_WAREHOUSE_SUCCESS, data: response.data });
//         }
//         else {
//             data.cb && data.cb(response, null);
//         }
//     } catch (e) {
//         console.log('get cbo warehouse error');
//     }
// }

// export function* watchGetCboWareHouse() {
//     while (true) {
//         const watcher = yield takeLatest(types.GET_CBO_WAREHOUSE, getCboWareHouse);
//         yield take(['LOGOUT', 'NETWORK']);
//         yield cancel(watcher);
//     }
// }


export function* getLoadCurrency(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/standard/load-currency', data.params);
        if (response && response.data) {
            yield put({ type: types.GET_LOAD_CURRENCY_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get load currency error');
    }
}

export function* watchGetLoadCurrency() {
    while (true) {
        const watcher = yield takeLatest(types.GET_LOAD_CURRENCY, getLoadCurrency);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getLoadEducation(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/standard/load-education', data.params);
        if (response && response.data) {
            yield put({ type: types.GET_LOAD_EDUCATION_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get load education error');
    }
}

export function* watchGetLoadEducation() {
    while (true) {
        const watcher = yield takeLatest(types.GET_LOAD_EDUCATION, getLoadEducation);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getLoadProfessionalLevel(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/standard/load-professional-level', data.params);
        if (response && response.data) {
            yield put({ type: types.GET_LOAD_PROFESSIONAL_LEVEL_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get load professional level error');
    }
}

export function* watchGetLoadProfessionalLevel() {
    while (true) {
        const watcher = yield takeLatest(types.GET_LOAD_PROFESSIONAL_LEVEL, getLoadProfessionalLevel);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get dates by trans
export function* getDateByTrans(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/standard/get-date-by-tran", data.params);
        yield put({ type: types.GENERAL_GET_DATE_BY_TRANS_SUCCESS, data: response.data });
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get date by trans error");
    }
}

export function* watchGetDateByTrans() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_DATE_BY_TRANS, getDateByTrans);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

//get cbo period..
export function* getCboPeriod(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/standard/load-cbo-period", data.params);
        yield put({ type: types.GENERAL_GET_CBO_PERIOD_SUCCESS, data: response.data });
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get cbo period error");
    }
}

export function* watchGetCboPeriod() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_PERIOD, getCboPeriod);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}


export function* getLoadListAttachments(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w84f3000/load-list-attachments', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get load list attachments error');
    }
}

export function* watchGetLoadListAttachments() {
    while (true) {
        const watcher = yield takeLatest(types.GET_LOAD_LIST_ATTACHMENT, getLoadListAttachments);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getLoadDepartments(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/standard/load-department', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get load department error');
    }
}

export function* watchGetLoadDepartments() {
    while (true) {
        const watcher = yield takeLatest(types.GET_LOAD_DEPARTMENT, getLoadDepartments);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCandidateProfile(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w25f2033/load-form', data.params);
        if (response && response.data) {
            yield put({ type: types.GET_CANDIDATE_PROFILE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get organizational error');
    }
}

export function* watchGetCandidateProfile() {
    while (true) {
        const watcher = yield takeLatest(types.GET_CANDIDATE_PROFILE, getCandidateProfile);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get duty..
export function* loadSetting(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/standard/load-setting', data.params);
        if (response && response.data) {
            yield put({ type: types.LOAD_SETTING_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load setting error');
    }
}

export function* watchLoadSetting() {
    while (true) {
        const watcher = yield takeLatest(types.LOAD_SETTING, loadSetting);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//export multi..
export function* exportMulti(data) {
    try {
        yield delay(300);
        const response = yield Api.putExportBuffer('/report/export-multiple', data.params);
        if (response && response.ok) {
            const content = yield response.data.blob();
            const _res = {
                fileName: data.params.TitleU,
                fileExt: "zip",
                URL: URL.createObjectURL(content),
            };
            if (_res && _res.URL) {
                data.cb && data.cb(null, _res);
            } else {
                data.cb && data.cb(_res, null);
            }
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load setting error', e);
    }
}

export function* watchExportMulti() {
    while (true) {
        const watcher = yield takeLatest(types.EXPORT_MULTI, exportMulti);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
