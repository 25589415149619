import {types} from "./main_actions";
import _ from "lodash";
const initialState = {
    color: null,
    user: null,
    setting: null,
    loading: null,
    checkLogin:null,
    numberBadge:null,
    filters: null,
    themes: [],
    badge: 0,
    listNoty: [],
    dataListReminderMaster: {total: 0, rows: []},
    dataListReminderDetails: {total: 0, rows: []},
    maintenance: null
};

export default function (state = initialState, action = {}) {
    switch (action.type){
        case types.GET_SETTING_COMPLETE:
            return{
                ...state,
                token: action.token,
                profile: action.profile,
                setting: action.setting,
                language:  action.language,
                color:  action.color,
                maintenance: action.maintenance
            };
        case types.CHANGE_THEME_COMPLETE:
            return{
                ...state,
                color:  action.color,
            };
        case types.UPDATE_FILTER_COMPLETE:
            return{
                ...state,
                filters:  action.filters,
            };
        case types.GET_THEME_COMPLETE:
            return{
                ...state,
                themes:  action.themes,
            };
        case types.UPDATE_BADGE_COMPLETE:
            return{
                ...state,
                badge: action.badge,
            };
        case types.LIST_NOTY_SUCCESS:
            return{
                ...state,
                listNoty: action.payload
            };
        case types.LIST_REMINDER_MASTER_SUCCESS:
            const totalMaster = _.get(action.payload, "total", 0);
            const rowsMaster = _.get(action.payload, "rows", []);
            return {
                ...state,
                dataListReminderMaster: {
                    total: totalMaster,
                    rows: _.uniqBy([...state.dataListReminderMaster.rows,...rowsMaster], "AlertBaseID")
                }
            };
        case types.LIST_REMINDER_DETAILS_SUCCESS:
            const totalDetails = _.get(action.payload, "total", 0);
            const rowsDetails = _.get(action.payload, "rows", []);
            return {
                ...state,
                dataListReminderDetails: {
                    total: totalDetails,
                    rows: _.uniqBy([...state.dataListReminderDetails.rows,...rowsDetails], "id")
                }
            };
        case types.SET_REMINDER_MASTER_EMPTY:
            return {
                ...state,
                dataListReminderMaster: {
                    total: 0,
                    rows: []
                }
            }
        case types.SET_REMINDER_DETAILS_EMPTY:
                return {
                    ...state,
                    dataListReminderDetails:{
                        total:0,
                        rows: []
                    }
                };
        default:
            return state;
    }
}
