import Api              from "../../services/api";
import {browserHistory} from "react-router";
import Config from "../../config";

export const types = {
    GET_PERMISSION: 'GET_PERMISSION',
    GET_PERMISSION_SUCCESS: 'GET_PERMISSION_SUCCESS',
    GET_CBO_ORGCHART: 'GET_CBO_ORGCHART',
    GET_CBO_ORGCHART_SUCCESS: 'GET_CBO_ORGCHART_SUCCESS',
    GET_CBO_GENDER: 'GET_CBO_GENDER',
    GET_CBO_GENDER_SUCCESS: 'GET_CBO_GENDER_SUCCESS',
    //filter
    GET_CBO_DUTY: 'GET_CBO_DUTY',
    GET_CBO_DUTY_SUCCESS: 'GET_CBO_DUTY_SUCCESS',
    GET_CBO_DUTY2: 'GET_CBO_DUTY2',
    GET_CBO_DUTY2_SUCCESS: 'GET_CBO_DUTY2_SUCCESS',
    GET_CBO_EMPLOYEE: 'GET_CBO_EMPLOYEE',
    GET_CBO_EMPLOYEE_SUCCESS: 'GET_CBO_EMPLOYEE_SUCCESS',
    GET_CBO_PROJECT: 'GET_CBO_PROJECT',
    GET_CBO_PROJECT_SUCCESS: 'GET_CBO_PROJECT_SUCCESS',
    GET_CBO_STATUS_WORK: 'GET_CBO_STATUS_WORK',
    GET_CBO_STATUS_WORK_SUCCESS: 'GET_CBO_STATUS_WORK_SUCCESS',
    GET_GRID_GENERAL_INFORMATION: "GET_GRID_GENERAL_INFORMATION",
    GENERAL_GET_CBO_DEPARTMENTS: 'GENERAL_GET_CBO_DEPARTMENTS',
    GENERAL_GET_CBO_DEPARTMENTS_SUCCESS: 'GENERAL_GET_CBO_DEPARTMENTS_SUCCESS',
    GENERAL_GET_CBO_TEAM: 'GENERAL_GET_CBO_TEAM',
    GENERAL_GET_CBO_TEAM_SUCCESS: 'GENERAL_GET_CBO_TEAM_SUCCESS',
    GENERAL_GET_CBO_WORKS: 'GENERAL_GET_CBO_WORKS',
    GENERAL_GET_CBO_WORKS_SUCCESS: 'GENERAL_GET_CBO_WORKS_SUCCESS',
    GET_CBO_APP_STATUS:"GET_CBO_APP_STATUS",
    GET_CBO_APP_STATUS_SUCCESS:"GET_CBO_APP_STATUS_SUCCESS",
    GENERAL_GET_ATTACHMENTS_BY_TRANSID: 'GENERAL_GET_ATTACHMENTS_BY_TRANSID',
    GENERAL_EXPORT_EXCEL_TEMPLATE: 'GENERAL_EXPORT_EXCEL_TEMPLATE',
    GENERAL_GET_CHECK_REQUIRED_FIELD: "GENERAL_GET_CHECK_REQUIRED_FIELD",
    GENERAL_GET_CHECK_REQUIRED_FIELD_SUCCESS: "GENERAL_GET_CHECK_REQUIRED_FIELD_SUCCESS",
    GET_CBO_WAREHOUSE: 'GET_CBO_WAREHOUSE',
    GET_CBO_WAREHOUSE_SUCCESS: 'GET_CBO_WAREHOUSE_SUCCESS',
    GET_LOAD_CURRENCY: "GET_LOAD_CURRENCY",
    GET_LOAD_CURRENCY_SUCCESS: "GET_LOAD_CURRENCY_SUCCESS",
    GET_LOAD_EDUCATION: "GET_LOAD_EDUCATION",
    GET_LOAD_EDUCATION_SUCCESS: "GET_LOAD_EDUCATION_SUCCESS",
    GET_LOAD_PROFESSIONAL_LEVEL: "GET_LOAD_PROFESSIONAL_LEVEL",
    GET_LOAD_PROFESSIONAL_LEVEL_SUCCESS: "GET_LOAD_PROFESSIONAL_LEVEL_SUCCESS",
    GENERAL_GET_DATE_BY_TRANS: "GENERAL_GET_DATE_BY_TRANS",
    GENERAL_GET_DATE_BY_TRANS_SUCCESS:"GENERAL_GET_DATE_BY_TRANS_SUCCESS",
    GENERAL_GET_CBO_PERIOD: "GENERAL_GET_CBO_PERIOD",
    GENERAL_GET_CBO_PERIOD_SUCCESS:"GENERAL_GET_CBO_PERIOD_SUCCESS",
    GET_LOAD_LIST_ATTACHMENT : "GET_LOAD_LIST_ATTACHMENT",
    GET_LOAD_DEPARTMENT : "GET_LOAD_DEPARTMENT",
    GET_CANDIDATE_PROFILE: "GET_CANDIDATE_PROFILE",
    GET_CANDIDATE_PROFILE_SUCCESS: "GET_CANDIDATE_PROFILE_SUCCESS",
    LOAD_SETTING: "LOAD_SETTING",
    LOAD_SETTING_SUCCESS: "LOAD_SETTING_SUCCESS",
    EXPORT_MULTI: "EXPORT_MULTI",
};

export function resetPermission() {
    return async (dispatch) => {
        dispatch({type: types.GET_PERMISSION_SUCCESS, data: 1});
    };
}

/**
 * Get permission
 * @param formIDs (array or string)
 * @param cb
 * @param flag <boolean> not return Not Permission page when permission = 0 (default: false)
 * @returns {Function}
 */
export function getPermission(formIDs, cb, flag) {
    const location         = browserHistory.getCurrentLocation();
    let menuFullPermission = {};
    let _formIDs           = formIDs;

    // if menuType = 0 (is ESS) return full permission (An BA)
    const state = Config.getUrlParams({location});
    if(Config.menuType === 0 && state?.voucher_id){
        return async (dispatch) => {
            !flag && dispatch({
                type: types.GET_PERMISSION_SUCCESS,
                data: 4
            });
            if (!Array.isArray(formIDs)) {
                cb && cb(4);
            }
            else{
                cb(formIDs.map(ID=>({FormID: ID,IsPermission: 4})));
            }
        }
    }

    //if is not form child permission and has state menu
    if (!flag && location?.state?.menu) {
        const menu=location.state.menu;
        if (!Array.isArray(formIDs)) {
            if (menu) _formIDs=menu.FormIDPermission || "";
            if (!_formIDs) {
                return async (dispatch) => {
                    !flag && dispatch({
                        type: types.GET_PERMISSION_SUCCESS,
                        data: 4
                    });
                    cb && cb(4);
                }
            }
        } else {
            _formIDs=formIDs.map(ID => {
                if (ID === menu.FormCall) {
                    if (menu.FormIDPermission) {
                        ID=menu.FormIDPermission;
                    } else {
                        menuFullPermission[ID]=true;
                    }
                }
                return ID;
            });
        }
    }

    let listScreenIDs = Array.isArray(_formIDs) ? _formIDs : [_formIDs];
    const params = {FormID: JSON.stringify(listScreenIDs)};

    return async (dispatch) => {
        !flag && dispatch({type: types.GET_PERMISSION_SUCCESS, data: null});
        await Api.put('/user/get-permission', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    !flag && dispatch({type: types.GET_PERMISSION_SUCCESS, data: 0});
                    cb && cb(0);
                } else if (result.data && result.data.length > 0) {
                    if (formIDs && Array.isArray(formIDs)) {
                        let data = [];
                        formIDs.forEach((ID, idx) => {
                            const per = result.data.find(d => d.FormID === _formIDs[idx]);
                            const isPermission = menuFullPermission[ID] ? 4 : (per ? per.IsPermission : 0);
                            data.push({
                                FormID: ID,
                                FormIDPermission: _formIDs[idx],
                                IsPermission: isPermission
                            })
                        });
                        !flag && dispatch({type: types.GET_PERMISSION_SUCCESS, data: data[0].IsPermission});
                        cb && cb(data);
                    }
                    else if (result.data[0] && result.data[0].IsPermission) {
                        !flag && dispatch({type: types.GET_PERMISSION_SUCCESS, data: result.data[0].IsPermission});
                        cb && cb(result.data[0].IsPermission);
                    }
                    else {
                        !flag && dispatch({type: types.GET_PERMISSION_SUCCESS, data: 0});
                        cb && cb(0);
                    }
                } else {
                    !flag && dispatch({type: types.GET_PERMISSION_SUCCESS, data: 0});
                    cb && cb(0);
                }
            });
    }
}

export function getCandidateProfile(params,cb) {
    return{
        type: types.GET_CANDIDATE_PROFILE,
        params,
        cb
    }
}

//get cbo orgcharts..
export function getOrgCharts(params, cb) {
    return{
        type: types.GET_CBO_ORGCHART,
        params,
        cb
    }
}

//get cbo duty..
export function getCboDuty(params, cb) {
    return{
        type: types.GET_CBO_DUTY,
        params,
        cb
    }
}

//get cbo duty..
export function getCboDuty2(cb) {
    return{
        type: types.GET_CBO_DUTY2,
        cb
    }
}

//get cbo employees..
export function getCboEmployees(params, cb) {
    return{
        type: types.GET_CBO_EMPLOYEE,
        params,
        cb
    }
}

//get cbo projects..
export function getCboProjects(params, cb) {
    return{
        type: types.GET_CBO_PROJECT,
        params,
        cb
    }
}

//get cbo departments..
export function getCboDepartments(params, cb) {
    return{
        type: types.GENERAL_GET_CBO_DEPARTMENTS,
        params,
        cb
    }
}

//get cbo departments..
export function getCboTeam(params, cb) {
    return{
        type: types.GENERAL_GET_CBO_TEAM,
        params,
        cb
    }
}

//get cbo departments..
export function getCboWorks(params, cb) {
    return{
        type: types.GENERAL_GET_CBO_WORKS,
        params,
        cb
    }
}

//get attachments by TransID (Key01ID)..
export function getAttachmentsByTransID(params, cb) {
    return{
        type: types.GENERAL_GET_ATTACHMENTS_BY_TRANSID,
        params,
        cb
    }
}

export function getCboAppStatus(params, cb) {
    return {
      type: types.GET_CBO_APP_STATUS,
      params,
      cb
    };
  }


//export excel template....
export function exportExcelTemplate(params, cb) {
    return{
        type: types.GENERAL_EXPORT_EXCEL_TEMPLATE,
        params,
        cb
    }
}

//Get Grid General Information
export function getGridGeneralInformation(params, cb) {
    return {
        type: types.GET_GRID_GENERAL_INFORMATION,
        params,
        cb
    }
}

export function getCboWarehouse(params, cb) {
    return {
        type: types.GET_CBO_WAREHOUSE,
        params,
        cb
    }
}

//Get status work...
export function getCboStatusWork(cb) {
    return {
        type: types.GET_CBO_STATUS_WORK,
        cb
    }
}

//Get Required Field
export function getRequiredFields(params, cb) {
    return {
        type: types.GENERAL_GET_CHECK_REQUIRED_FIELD,
        params,
        cb
    };
  }
// get cbo warehouse
// export function getCboWareHouse(cb) {
//     return {
//         type: types.GET_CBO_WAREHOUSE,
//         cb
//     }
// }

export function getCboGenders(cb) {
    return {
        type: types.GET_CBO_GENDER,
        cb
    }
}

export function getLoadCurrency(params,cb) {
    return {
        type: types.GET_LOAD_CURRENCY,
        params,
        cb
    }
}

export function getLoadEducation(params,cb) {
    return {
        type: types.GET_LOAD_EDUCATION,
        params,
        cb
    }
}

export function getLoadProfessionalLevel(params,cb) {
    return {
        type: types.GET_LOAD_PROFESSIONAL_LEVEL,
        params,
        cb
    }
}

//get ranger date by trans..
export function getDateByTrans(params, cb) {
    return {
        type: types.GENERAL_GET_DATE_BY_TRANS,
        params,
        cb
    }
}

//get cbo period..
export function getCboPeriod(params, cb) {
    return {
        type: types.GENERAL_GET_CBO_PERIOD,
        params,
        cb
    }
}

export function getLoadListAttachments(params, cb) {
    return {
        type: types.GET_LOAD_LIST_ATTACHMENT,
        params,
        cb
    }
}

export function getLoadDepartments(params, cb) {
    return {
        type: types.GET_LOAD_DEPARTMENT,
            params,
            cb
    }
}

export function saveHistory(params, cb) {
    return async () => {
        return await Api.put('/history/add', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    cb && cb(result, null);
                    return null;
                } else if (result.data) {
                    cb && cb(null, result.data);
                    return result.data;
                } else {
                    cb && cb(result, null);
                    return null;
                }
            });
    }
}

export function saveHistory2(params, cb) {
    return async () => {
        return await Api.put('/history/add', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    cb && cb(result, null);
                    return null;
                } else if (result.data) {
                    cb && cb(null, result.data);
                    return result.data;
                } else {
                    cb && cb(result, null);
                    return null;
                }
            });
    }
}

export function loadSetting(params, cb) {
    return {
        type: types.LOAD_SETTING,
        params,
        cb
    }
}

export function exportMulti(params, cb) {
    return {
        type: types.EXPORT_MULTI,
        params,
        cb
    }
}








