/**
 * @copyright 2020 @ DigiNet
 * @author HUYTRAN
 * @create 10/30/2020
 * @Example 
 */

import Api from '../../services/api';
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./history_actions";
import { delay } from "redux-saga";

export default function HistorySagas() {
    return [
        watchHistorySearch(),
        watchHistoryDelete(),
    ];
}

//history Search..
export function* historySearch(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/history/search', data.params);
        if (response && response.data) {
            yield put({ type: types.HISTORY_SEARCH_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('history Search error');
    }

}

export function* watchHistorySearch() {
    while (true) {
        const watcher = yield takeLatest(types.HISTORY_SEARCH, historySearch);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//history Delete ..
export function* historyDelete(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/history/del', data.params);
        if (response && response.data) {
            yield put({ type: types.HISTORY_DELETE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('history del error');
    }

}

export function* watchHistoryDelete() {
    while (true) {
        const watcher = yield takeLatest(types.HISTORY_DELETE, historyDelete);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}