import {types} from "./approvals_actions";

const initialState = {
    getCboStatus: [],
    getGridApproval: [],
    getAttachments: [],
    getHistorys: [],
    getRegisterTypes: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.APPROVALS_GET_REGISTER_TYPES_SUCCESS:
            return {
                ...state,
                getRegisterTypes: action.data
            };
        case types.APPROVALS_GET_CBO_STATUS_SUCCESS:
            return {
                ...state,
                getCboStatus: action.data
            };
        case types.APPROVALS_GET_GRID_SUCCESS:
            return {
                ...state,
                getGridApproval: action.data
            };
        case types.APPROVALS_GET_ATTACHMENTS_SUCCESS:
            return {
                ...state,
                getAttachments: action.data
            };
        case types.APPROVALS_GET_HISTORYS_SUCCESS:
            return {
                ...state,
                getHistorys: action.data
            };
        default:
            return state;
    }
}