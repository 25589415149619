/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/31/2019
 * @Example 
 */

import Api from '../../services/api';
import {put, cancel, take, takeLatest} from "redux-saga/effects";
import {types} from "../approvals/approvals_actions";
import {delay} from "redux-saga";

export default function ApprovalSagas() {
    return [
        watchGetCboStatus(),
        watchGetGridApprovals(),
        watchGetAttachments(),
        watchGetHistorys(),
        watchSaveApprovals(),
        watchCancelApprovals(),
        watchGetFormInfo(),
        watchGetCboApprovalTransType(),
        watchGetRegisterTypes(),
    ];
}

export function* getCboApprovalTransType(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/standard/load-aproval-transtype');
        if (response && response.data) {
            yield put({type: types.APPROVALS_GET_CBO_TRANSTYPE_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo status error');
    }

}

export function* watchGetCboApprovalTransType() {
    while (true){
        const watcher = yield takeLatest(types.APPROVALS_GET_CBO_TRANSTYPE, getCboApprovalTransType);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

//get register types..
export function* getRegisterTypes(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w75f2000/load-register-type');
        if (response && response.data) {
            yield put({type: types.APPROVALS_GET_REGISTER_TYPES_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get register types error');
    }

}

export function* watchGetRegisterTypes() {
    while (true){
        const watcher = yield takeLatest(types.APPROVALS_GET_REGISTER_TYPES, getRegisterTypes);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo status..
export function* getCboStatus(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w84f3000/load-approval-status', data.params);
        if (response && response.data) {
            yield put({type: types.APPROVALS_GET_CBO_STATUS_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo status error');
    }

}

export function* watchGetCboStatus() {
    while (true){
        const watcher = yield takeLatest(types.APPROVALS_GET_CBO_STATUS, getCboStatus);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

//get attachments..
export function* getAttachments(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w84f3000/load-list-attachments', data.params);
        if (response && response.data) {
            yield put({type: types.APPROVALS_GET_ATTACHMENTS_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get attachments error');
    }

}

export function* watchGetAttachments() {
    while (true){
        const watcher = yield takeLatest(types.APPROVALS_GET_ATTACHMENTS, getAttachments);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

//get historys..
export function* getHistorys(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w84f3000/load-history-tab', data.params);
        if (response && response.data) {
            yield put({type: types.APPROVALS_GET_HISTORYS_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get historys error');
    }

}

export function* watchGetHistorys() {
    while (true){
        const watcher = yield takeLatest(types.APPROVALS_GET_HISTORYS, getHistorys);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

//get grid approvals..
export function* getGridApprovals(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w84f3000/load-list-voucher-left', data.params);
        if (response && response.data) {
            yield put({type: types.APPROVALS_GET_GRID_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get grid approvals error');
    }

}

export function* watchGetGridApprovals() {
    while (true){
        const watcher = yield takeLatest(types.APPROVALS_GET_GRID, getGridApprovals);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

//save approvals..
export function* saveApprovals(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w84f3000/save-approval', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save approvals error');
    }

}

export function* watchSaveApprovals() {
    while (true){
        const watcher = yield takeLatest(types.APPROVALS_SAVE, saveApprovals);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

//cancel approvals..
export function* cancelApprovals(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w84f3000/cancel-approval', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('cancel approvals error');
    }

}

export function* watchCancelApprovals() {
    while (true){
        const watcher = yield takeLatest(types.APPROVALS_CANCEL, cancelApprovals);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

//get form info approvals..
export function* getFormInfo(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/standard/load-form-approval', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get form info error');
    }

}

export function* watchGetFormInfo() {
    while (true){
        const watcher = yield takeLatest(types.APPROVALS_GET_FORM_INFO, getFormInfo);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
