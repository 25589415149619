
export const types = {
    ADMIN_LOGIN: 'ADMIN_LOGIN',
    TEST_CONNECT: 'TEST_CONNECT',
};

export function login(params, cb) {
    return{
        type: types.ADMIN_LOGIN,
        params,
        cb
    }
}

export function testConnect(params, cb) {
    return{
        type: types.TEST_CONNECT,
        params,
        cb
    }
}