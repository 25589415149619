/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/7/2020
 * @Example 
 */

import Api from '../../services/api';
import {delay} from "redux-saga";
import {cancel, take, takeLatest} from "redux-saga/effects";
import {types} from "../../redux/admin/admin_actions";

export default function AdminSagas() {
    return [
        watchAdminLogin(),
        watchTestConnect(),
    ];
}

export function* adminLogin(data) {
    try {
        yield delay(300);
        const login = yield Api.put('/user/admin-login',data.params);
        if (login && login.code && login.message) {
            if (data.cb) data.cb(login, null);
        } else {
            if (data.cb) data.cb(null, login.data);
        }
    }
    catch (e) {
        console.log('admin login is error');
    }

}

export function* watchAdminLogin() {
    while (true){
        const watcher = yield takeLatest(types.ADMIN_LOGIN, adminLogin);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* testConnect(data) {
    try {
        yield delay(300);
        const testConnect = yield Api.put('/connection/test',data.params);
        if (testConnect && testConnect.data) {
            data.cb && data.cb(null,testConnect.data)
        }
        else{
            data.cb && data.cb(testConnect,null)
        }
    }
    catch (e) {
        console.log('testConnect error');
    }
}

export function* watchTestConnect() {
    while (true){
        const watcher = yield takeLatest(types.TEST_CONNECT,testConnect);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}