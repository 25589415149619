import {types} from "./general_actions";
const initialState = {
    iPermission: null,
    getOrgCharts: [],
    getCboDuty: [],
    getCboDuty2: [],
    getCboEmployees: [],
    getCboProjects: [],
    getCboDepartments: [],
    getCboTeam: [],
    getCboWorks: [],
    getCboHandOver: [],
    getCboProjectsW29f2005: [],
    getCboAppStatus: [],
    getRequiredFields: [],
    getCboGenders: [],
    getCboStatusWork: [],
    getCboWarehouse: [],
    getLoadCurrency: [],
    getLoadEducation: [],
    getLoadProfessionalLevel: [],
    getDateByTrans: [],
    getCboPeriod: [],
    getCandidateProfile: {},
    loadSetting: []
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.GET_PERMISSION_SUCCESS:
            return {
                ...state,
                iPermission: action.data
            };
        case types.GET_CBO_ORGCHART_SUCCESS:
            return {
                ...state,
                getOrgCharts: action.data
            };
        case types.GET_CBO_DUTY_SUCCESS:
            return {
                ...state,
                getCboDuty: action.data
            };
        case types.GET_CBO_DUTY2_SUCCESS:
            return {
                ...state,
                getCboDuty2: action.data
            };
        case types.GET_CBO_PROJECT_SUCCESS:
            return {
                ...state,
                getCboProjects: action.data
            };
        case types.GENERAL_GET_CBO_DEPARTMENTS_SUCCESS:
            return {
                ...state,
                getCboDepartments: action.data
            };
        case types.GENERAL_GET_CBO_TEAM_SUCCESS:
            return {
                ...state,
                getCboTeam: action.data
            };
        case types.GENERAL_GET_CBO_WORKS_SUCCESS:
            return {
                ...state,
                getCboWorks: action.data
            };
        case types.GET_CBO_EMPLOYEE_SUCCESS:
            return {
                ...state,
                getCboEmployees: action.data
            };
        case types.GET_CBO_APP_STATUS_SUCCESS:
            return {
                ...state,
                getCboAppStatus: action.data
            };
        case types.GENERAL_GET_CHECK_REQUIRED_FIELD_SUCCESS: {
            return { ...state, getRequiredFields: action.data };
        }
        case types.GET_CBO_GENDER_SUCCESS:
            return {
                ...state,
                getCboGenders: action.data
            };
        case types.GET_CBO_STATUS_WORK_SUCCESS:
            return {
                ...state,
                getCboStatusWork: action.data
            };
        case types.GET_CBO_WAREHOUSE_SUCCESS:
            return {
                ...state,
                getCboWarehouse: action.data
            };
        case types.GET_LOAD_CURRENCY_SUCCESS:
            return {
                ...state,
                getLoadCurrency: action.data
            };
        case types.GET_LOAD_EDUCATION_SUCCESS:
            return {
                ...state,
                getLoadEducation: action.data
            };
        case types.GET_LOAD_PROFESSIONAL_LEVEL_SUCCESS:
            return {
                ...state,
                getLoadProfessionalLevel: action.data
            };
        case types.GENERAL_GET_DATE_BY_TRANS_SUCCESS:
            return {
                ...state,
                getDateByTrans: action.data
            };
        case types.GENERAL_GET_CBO_PERIOD_SUCCESS:
            return {
                ...state,
                getCboPeriod: action.data
            };
        case types.GET_CANDIDATE_PROFILE_SUCCESS:
            return {
                ...state,
                getCandidateProfile: action.data
            };
        case types.LOAD_SETTING_SUCCESS:
            return {
                ...state,
                loadSetting: action.data
            }
        default:
            return state;
    }
}
