import {delay} from 'redux-saga';
import {put, takeLatest, take, cancel} from 'redux-saga/effects';
import {types} from "./home_actions";
import Api from '../../services/api';
import Config from "../../config";

export default function HomeSagas() {
    return [
        watchGetAuth(),
        watchList(),
        watchGetMenuV2(),
        watchGetEss(),
        watchGetMss(),
        watchGetFormInfo(),
        watchGetListBirthday(),
        watchGetFlagShowEOffice(),
        watchGetListEOffice(),
    ];
}

//##############################
export function* getAuth(data) {
    try {
        yield delay(300);
        const response =yield Api.put('/auth/link', data.params);
        if(response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get auth error');
    }
}

export function* watchGetAuth() {
    while(true) {
        const watcher = yield takeLatest(types.GET_AUTH, getAuth);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
//##############################
export function* getList(data) {
    // console.log(data);
    try {
        yield delay(300);
        const list = yield Api.get('https://5d2fd45028465b00146aa88c.mockapi.io/api/v1/dates');
        if(list){
            yield put({type: types.LIST + '_SUCCESS', payload: list}); //đi qua lieu vao reducer
            data.cb && data.cb(null, list);
            return;
        }
        else{
            data.cb && data.cb(list, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchList() {
    while (true){
        const watcher = yield takeLatest(types.LIST,getList);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################

//##############################
export function* getMenuV2(data) {
    // console.log(data);
    try {
        yield delay(300);
        const list = yield Api.put('/w00f1000/get-menu-v210', data.params);
        if(list){
            data.cb && data.cb(null, list.data);
            return false;
        }
        else{
            data.cb && data.cb(list, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchGetMenuV2() {
    while (true){
        const watcher = yield takeLatest(types.LIST_MENU, getMenuV2);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
// ##############################

//##############################

export function* getEss(data) {
    // console.log(data);
    try {
        yield delay(300);
        const list = yield Api.put('/w00f1000/get-menu');
        if(list){
            // yield put({type: types.LIST_ESS + '_SUCCESS', payload: list}); //đi qua lieu vao reducer
            data.cb && data.cb(null, list.data);
            return;
        }
        else{
            data.cb && data.cb(list, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchGetEss() {
    while (true){
        const watcher = yield takeLatest(types.LIST_ESS,getEss);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################

//##############################
export function* getMss(data) {
    // console.log(data);
    try {
        yield delay(300);
        const list = yield Api.put('/W00F1000/get-mss');
        if(list && list.data && list.code === 200){
            // yield put({type: types.LIST_ESS + '_SUCCESS', payload: list}); //đi qua lieu vao reducer
            data.cb && data.cb(null, list.data);
        }
        else{
            data.cb && data.cb(list, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchGetMss() {
    while (true){
        const watcher = yield takeLatest(types.LIST_MSS,getMss);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################

//##############################
export function* getFormInfo(data) {
    // console.log(data);
    try {
        yield delay(300);
        const list = yield Api.put('/w00f1000/form-data',{Language:Config.language});
        if(list && list.data && list.code === 200){
            // yield put({type: types.LIST_ESS + '_SUCCESS', payload: list}); //đi qua lieu vao reducer
            data.cb && data.cb(null, list.data);
        }
        else{
            data.cb && data.cb(list, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchGetFormInfo() {
    while (true){
        const watcher = yield takeLatest(types.LIST_FORM_INFO,getFormInfo);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################

//##############################
export function* getListBirthday(data) {
    // console.log(data);
    try {
        yield delay(300);
        const list = yield Api.put('/W00F1000/get-birthday-in-week',{Language:Config.language});
        if(list && list.data && list.code === 200){
            yield put({type: types.LIST_BIRTHDAY + '_SUCCESS', payload: list.data}); //đi qua lieu vao reducer
            data.cb && data.cb(null, list.data);
        }
        else{
            data.cb && data.cb(list, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchGetListBirthday() {
    while (true){
        const watcher = yield takeLatest(types.LIST_BIRTHDAY,getListBirthday);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
// ##############################
export function* getFlagShowEOffice(data) {
    // console.log(data);
    try {
        yield delay(300);
        const list = yield Api.get('/w00f1000/get-flag');
        if(list && list.data && list.code === 200){
            data.cb && data.cb(null, list.data);
        }
        else{
            data.cb && data.cb(list, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchGetFlagShowEOffice() {
    while (true){
        const watcher = yield takeLatest(types.SHOW_EOFFICE,getFlagShowEOffice);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
//##############################
export function* getListEOffice(data) {
    try {
        yield delay(300);
        const list = yield Api.put('/w00f1000/get-news-category', data.params);
        if(list && list.data && list.code === 200){
            yield put({type: types.LIST_EOFFICE + '_SUCCESS', payload: list.data}); //đi qua lieu vao reducer
            data.cb && data.cb(null, list.data);
        }
        else{
            data.cb && data.cb(list, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchGetListEOffice() {
    while (true){
        const watcher = yield takeLatest(types.LIST_EOFFICE,getListEOffice);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
